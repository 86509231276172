<template>
    <div class="application-catalog">
        <h2 class="header">{{ t('products') }}</h2>
        <BaseChipSelect :options="homeCategories.map(el => ({ label: el.name[locale], value: el.id }))"
            v-model="currentId" :disabled="isLoading" />
        <div class="products">
            <TransitionGroup name="catalog">
                <NuxtLink v-for="(prod, i) in products" :key="prod.id"
                    :to="localePath(prod.type === 'group' ? `/catalog/category/${currentId}/group/${prod.id}` : `/catalog/category/${currentId}/product/${prod.id}`)"
                    class="item" :style="`--delay: ${50 * i}ms`">
                    <div class="image-wrapper">
                        <img :src="prod.image" :alt="prod.slug">
                    </div>
                    <div>{{ prod.title[locale] }}</div>
                </NuxtLink>
            </TransitionGroup>
        </div>

    </div>
</template>

<script setup>
const rc = useRuntimeConfig();
const { locale, t } = useI18n();
const localePath = useLocalePath();
const { state, initStore } = useMainStore();
await initStore();
const { start, isLoading, finish } = useLoadingIndicator()
const homeCategories = computed(() => state.home.categories.filter(el => el.show_on_home_page))
const currentId = ref(homeCategories.value[0]?.id);
const pagination = reactive({
    page: 1,
    total: 0,
})
const products = ref([])
async function fetchProducts() {
    start()
    products.value = []
    const query = {
        page: pagination.page,
        'filters[category_id]': currentId.value,
        'filters[show_on_home_page]': 1,
    }
    const res = await $fetch(rc.public.api + "/api/product/products-and-groups", {
        query,
    });
    products.value = res.data.list
    finish()
}
await fetchProducts();

watch(() => currentId.value,
    () => fetchProducts())
</script>