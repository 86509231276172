<template>
    <div class="home-subscription">
        <div class="my-container">
            <h2 class="header">{{ t('subscriptionToNews') }}</h2>
            <p>{{ t('subscriptionDescription') }}</p>
            <form class="wrapper" @submit.prevent="sendForm" :class="{ error: validator.email.$error }" v-if="isForm">
                <input type="email" name="email" :placeholder="t('yourEmail')" v-model="form.email" class="w-full" />
                <button class="my-btn">{{ t('toSubscript') }}</button>
            </form>
            <p v-else>{{ t('subscriptionCongrats') }}</p>
        </div>
    </div>
</template>

<script setup>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
const rc = useRuntimeConfig()
const { locale, t } = useI18n()
const { start, finish } = useLoadingIndicator()
const toast = useToast();
const form = reactive({
    email: ''
})
const rules = computed(() => ({
    email: { required, email }
}))
const validator = useVuelidate(rules, form)
const isForm = ref(true)
async function sendForm() {
    const isValid = await validator.value.$validate()
    if (!isValid) return toast.add({
        title: t('fillForm'),
        color: 'red'
    });
    try {
        start()
        await $fetch(rc.public.api + '/api/application/mailing', {
            body: {
                email: form.email
            },
            method: 'POST'
        })
        toast.add({
            title: t('subscriptionSuccesfull'),
        })
        form.email = '';
        isForm.value=false
    } catch (error) {
        toast.add({
            title: error?.data?.context?.message || error?.data?.message || 'Internal error',
            color: 'red'
        })
    } finally {
        finish()
    }
}

</script>