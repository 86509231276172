<template>
    <div class="product-series">
        <div class="entry" v-for="s in series"
            :style="'background:' + `url('${s.banner[locale]}') no-repeat center center/cover;`">
            <div class="overlay">
                <NuxtLink :to="localePath(`/catalog/series/${s.id}`)" class="my-btn secondary">{{ t('detailed') }}
                </NuxtLink>
            </div>
        </div>
        <div style="text-align: center;">
            <NuxtLink :to="localePath('/catalog/series')" class="my-btn">{{ t('viewAllSeries') }}</NuxtLink>
        </div>
    </div>
</template>

<script setup>
const { locale, t } = useI18n()
const localePath = useLocalePath()

const props = defineProps(['series'])

</script>