<template>
    <div class="home-news">
        <h2 class="header">{{ t('readOurNews') }}</h2>
        <NuxtLink :to="localePath('/news')" class="my-btn pc flex align-center justify-center" style="min-width: 100px">
            {{ t('allNews') }}
        </NuxtLink>
        <div class="news">
            <ApplicationNewsCard v-for="entry in state.home.posts" :key="entry.id" :news-data="entry" />
            <NuxtLink :to="localePath('/news')" class="my-btn mobile">
                {{ t('allNews') }}
            </NuxtLink>
        </div>
    </div>
</template>
<script setup>
const { state, initStore } = useMainStore();
const { t, locale } = useI18n()
const localePath = useLocalePath()
await initStore()
</script>