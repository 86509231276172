<template>
    <div class="home-page">
        <div class="hero"
            :style="`background: url('${state.home.home_sections['section-1'].preview_image}') no-repeat center center/cover;`">
            <video class="pc" autoplay loop muted style="position: absolute; left: 0; top: 0; width: 100%;
            height: 100%; object-fit: cover; object-position: center;" playsinline>
                <source :src="state.home.home_sections['section-1'].media_file" type="video/mp4" />
            </video>
            <div class="hero-text" style="z-index:2">
                <div class="slogan">{{ state.home.home_sections['section-1'].title[locale] }}</div>
                <div class="description">{{ state.home.home_sections['section-1'].description[locale] }}</div>
            </div>
        </div>
        <div class="my-container section">
            <HomeCatalog />
        </div>
        <div class="my-container section">
            <HomeProductSeries :series="state.home.series" />
        </div>
        <div class="section">
            <HomeBrands :image="state.home.home_sections.our_brands.media_file" />
        </div>
        <div class="section">
            <HomeAbout :about="state.home.home_sections.about" />
        </div>
        <client-only>
            <div class="my-container section">
                <LazyHomeMap2 />
            </div>
        </client-only>
        <HomeSubscription class="section" />
        <HomeNews class="section my-container" />
        <HomeFaq class="section my-container" :faqs="state.home.faqs" />
    </div>
</template>
<script setup>
const { t, locale } = useI18n()
const { state, initStore } = useMainStore();
await initStore()
const heroStyles = computed(() => {
    if (state.home.home_sections)
        return {
            // background: `url('${state.home.home_sections.value['section-1'].media_file}') no-repeat center center/cover;`
            background: ``
        }
    return {};
})
</script>
