<template>
    <div class="home-brands">
        <h2 class="header">{{ t('ourBrands2') }}</h2>
        <div class="brand-wrapper" :style="styles">
            <div class="overlay">
                <vue-marquee-slider :paused id="marquee-slider-pc-loop" :space="0" :speed="50000" :width="280"
                    class="pc">
                    <NuxtLink @mouseenter="paused = true" @mouseleave="paused = false"
                        :to="localePath(`/catalog/brand/${brand.id}`)" v-for="(brand, index) in state.home.brands"
                        :key="brand.id" class="img-wrapper">
                        <img :src="brand.image" :alt="brand.name" />
                    </NuxtLink>
                </vue-marquee-slider>
                <vue-marquee-slider :paused id="marquee-slider-mobile-loop" :space="50" :speed="50000" :width="160"
                    class="mobile">
                    <NuxtLink :to="localePath(`/catalog/brand/${brand.id}`)"
                        v-for="(brand, index) in state.home.brands" :key="brand.id" class="img-wrapper">
                        <img :src="brand.image" :alt="brand.name" />
                    </NuxtLink>
                </vue-marquee-slider>
            </div>
        </div>
    </div>
</template>

<script setup>
import { VueMarqueeSlider } from 'vue3-marquee-slider';
import './././node_modules/vue3-marquee-slider/dist/style.css'
const localePath = useLocalePath()
const { state, initStore } = useMainStore();
const { t } = useI18n()
const props = defineProps(['image'])
await initStore();
const styles = computed(() => ({
    backgroundImage: `url(${props.image})`
}))
const paused = ref(false)
</script>